/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Navbar from "./navbar-scroll"
import Footer from "./footer"
import FloatBtn from "./floating-button"

import "./layout.css"

const Layout = ({ children }) => {
  return (
    <>
      <header
        style={{
          position: "fixed",
          width: "100%",
          zIndex: "1000",
        }}
      >
        <Navbar />
      </header>
      <main>{children}</main>
      <Footer />
      <FloatBtn />

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
