import React from "react"
import Img from "gatsby-image"
import styles from "./page-header.module.scss"

export default props => (
  <section>
    <div className={styles.heroWrapper}>
      <Img fluid={props.bg} className={styles.backgroundImage} />
      <div className={styles.overlay}>
        <div className={"container-sm " + styles.flexContainer}>
          <div className={styles.heroContent}>
            <h1>{props.title}</h1>
          </div>
        </div>
      </div>
    </div>
  </section>
)
