import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React, { useState } from "react"
import { Navbar, Nav } from "react-bootstrap"
import styles from "./navbar-scroll.module.scss"

const Header = () => {
  const data = useStaticQuery(graphql`
    query SiteMetadataQuery {
      site {
        siteMetadata {
          title
          root
          menuLinks {
            name
            link
          }
        }
      }
      allFile(
        filter: {
          absolutePath: { regex: "/logo-org/" }
          childImageSharp: { fluid: {} }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fixed(height: 40, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)
  const siteMetadata = data.site.siteMetadata
  const logos = data.allFile.edges.map(({ node }) => node)
  const [isExpanded, setIsExpanded] = useState(false)
  const toggle = () => {
    setIsExpanded(!isExpanded)
  }
  if (typeof window !== "undefined") {
    let prevScrollpos = window.pageYOffset
    window.addEventListener(
      "scroll",
      evt => {
        const maxScroll = document.body.clientHeight - window.innerHeight
        let currentScrollPos = window.pageYOffset
        if (
          (maxScroll > 0 &&
            prevScrollpos > currentScrollPos &&
            prevScrollpos <= maxScroll) ||
          (maxScroll <= 0 && prevScrollpos > currentScrollPos) ||
          (prevScrollpos <= 0 && currentScrollPos <= 0)
        ) {
          document.getElementById("navbar").style.top = "0"
          if (currentScrollPos < 200) {
            document.getElementById("navbar").style.top = "-5.0rem"
          }
        } else {
          document.getElementById("navbar").style.top = "-5.0rem" // adjustable based your need
        }
        setIsExpanded(false)
        prevScrollpos = currentScrollPos
      },
      false
    )
  }

  return (
    <Navbar
      id="navbar"
      className={styles.navbar}
      collapseOnSelect
      expand="lg"
      expanded={isExpanded}
      onToggle={toggle}
      bg="light"
      variant="light"
    >
      <div
        className="container-xl"
        styles={{
          display: "flex",
        }}
      >
        <Link
          to={siteMetadata.root}
          style={{
            flex: "1 1 30%",
          }}
        >
          <Navbar.Brand className="d-none d-lg-flex align-items-center py-0">
            <Img
              style={{
                maxHeight: 40,
              }}
              fixed={logos[1].childImageSharp.fixed}
              imgStyle={{
                marginBottom: 0,
              }}
            />
          </Navbar.Brand>
          <Navbar.Brand className="d-lg-none d-flex align-items-center">
            <Img
              style={{
                maxHeight: 40,
              }}
              fixed={logos[0].childImageSharp.fixed}
            />
            <span
              className="ml-2"
              style={{
                textDecoration: "none",
                color: "#2e75b6",
                fontFamily: "Lucida Bright",
              }}
            >
              G<small>&</small>A
            </span>
          </Navbar.Brand>
        </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav"
          style={{
            fontFamily: "Playfair Display",
            fontSize: 16,
            flex: "1 1 70%",
          }}
        >
          <Nav className="ml-auto d-flex align-items-center">
            {siteMetadata.menuLinks.map(link => (
              <Link
                exact
                className="nav-link px-2 text-center"
                activeClassName="active"
                style={{
                  opacity: 1,
                }}
                to={link.link}
              >
                {link.name}
              </Link>
            ))}
          </Nav>{" "}
        </Navbar.Collapse>
      </div>

    </Navbar>
  )
}

export default Header
