import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import styles from "./footer.module.scss"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          menuLinks {
            name
            link
          }
          contacto{
            phone{
              data
              icon
              title
            }
            email{
              data
              icon
              title
            }
            address{
              data
              icon
              title
            }
            open{
              data
              icon
              title
            }
          }
        }
      }
    }
  `)
  return (
    <footer
      className={"pt-5 " + styles.container}
    >
      <Container className="pt-5 pb-2 ">
        <Row className={styles.footer}>
          <Col sm={6} lg={3} className="mb-4">
            <h5>{data.site.siteMetadata.title}</h5>
            <p>{data.site.siteMetadata.description}</p>
          </Col>
          <Col sm={6} lg={{ span: 2, offset: 1 }} className="mb-4">
            <h5>Mapa del Sitio</h5>
            {data.site.siteMetadata.menuLinks.map(link => (
              <>
                <Link to={link.link}>{link.name}</Link>
                <br />
              </>
            ))}
          </Col>
          <Col lg={{ span: 5, offset: 1 }} className="mb-4">
            <h5>Información de Contacto</h5>
            {Object.keys(data.site.siteMetadata.contacto).map(key => (
              <div class="media mb-4" style={{
                color: '#ffffff80'
              }}>
                <i className={`${data.site.siteMetadata.contacto[key].icon} mr-3`}></i>
                <div className="media-body">
                  <h6 className="my-0">{data.site.siteMetadata.contacto[key].title}</h6>
                  <span>
                    {data.site.siteMetadata.contacto[key].data}
                  </span>
                </div>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
