import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"
import React, { useState } from "react"
import { Navbar, Nav } from "react-bootstrap"
import styles from "./navbar-top.module.scss"

const Header = props => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          root
          menuLinks {
            name
            link
          }
        }
      }
      allFile(
        filter: {
          absolutePath: { regex: "/logo-inv/" }
          childImageSharp: { fluid: {} }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              fixed(height: 50, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `)
  const siteMetadata = data.site.siteMetadata
  const logos = data.allFile.edges.map(({ node }) => node)
  const [isOpen, setIsOpen] = useState(false)
  const toggle = expanded => {
    setIsOpen(expanded)
  }
  // if (typeof window !== "undefined") {   ////ESTO ES PARA CERRAR EL MENÚ CUANDO SE ESCROLEA
  //   window.onscroll = function() {       ////Esta función onscroll reemplaza la que hay en el otro navbar y genera conflicto
  //     setIsOpen(false)
  //   }
  // }
  return (
    <header
      style={{
        position: "absolute",
        width: "100%",
        top: 0,
        zIndex: "1000",
      }}
    >
      <Navbar
        id="navbar"
        className={
          styles.navbar +
          " " +
          (props.hero ? styles.hero + " " : "") +
          (isOpen ? styles.expanded : "")
        }
        onToggle={toggle}
        collapseOnSelect
        expand="lg"
        variant={isOpen ? "light" : "dark"}
      >
        <div
          className="container-sm"
          styles={{
            display: "flex",
          }}
        >
          <Link
            to={siteMetadata.root}
            style={{
              flex: "1 1 40%",
            }}
          >
            <Navbar.Brand className="d-none d-lg-flex items-align-center">
              <Img
                style={{
                  maxHeight: 50,
                }}
                fixed={logos[1].childImageSharp.fixed}
                imgStyle={{
                  marginBottom: 0,
                }}
              />
            </Navbar.Brand>
            <Navbar.Brand className="d-lg-none pl-3 d-flex align-items-center">
              <Img
                style={{
                  maxHeight: 50,
                }}
                fixed={logos[0].childImageSharp.fixed}
              />{" "}
              <span
                className={"ml-2 "+styles.logoSm}
                style={{
                  color: isOpen? "#2e75b6": "#d0d0d0",
                  fontFamily: "Lucida Bright"
                }}
              >
                G<small>&</small>A
              </span>
            </Navbar.Brand>
          </Link>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse
            id="responsive-navbar-nav"
            style={{
              fontFamily: "Playfair Display",
              fontSize: 16,
              flex: "1 1 60%",
            }}
          >
            <Nav className="ml-auto d-flex align-items-center">
              {siteMetadata.menuLinks.map(link => (
                <Link
                  exact
                  className="nav-link px-2 text-center"
                  activeClassName="active"
                  style={{
                    opacity: 1,
                  }}
                  to={link.link}
                >
                  {link.name}
                </Link>
              ))}
            </Nav>{" "}
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  )
}

export default Header
