import React from "react"
import styles from "./floating-button.module.scss"

export default () => {
  return (
    <a href="https://wa.me/56978454447" target="_blank" className={styles.float}>
      <i class={`fab fa-whatsapp fa-2x ${styles.my_float}`}></i>
    </a>
  )
}
